import * as React from 'react'
import ReactMarkdown from 'react-markdown'
import scrollTo from 'gatsby-plugin-smoothscroll'
import Accordion from '../../../Accordion/Accordion'

import './ResidencyGroup.css'

export interface IResidencyGroup {
  title: string
  description: string
  exampleProjectAreas: string
  selectedIndex?: number | null
  index: number
  onClickAccordion: React.Dispatch<React.SetStateAction<number | null>>
}

const ResidencyGroup = ({
  title,
  description,
  exampleProjectAreas,
  selectedIndex,
  index,
  onClickAccordion,
}: IResidencyGroup) => {
  const onClickHandler = () => {
    return index === selectedIndex
      ? onClickAccordion(null)
      : onClickAccordion(index)
  }
  return (
    <div className="ResidencyGroup" data-testid="residency-group">
      <h3 className="mb-6 text-2xl">{title}</h3>
      <div className="min-h-25">
        <p className="mb-6">{description}</p>
      </div>
      <Accordion
        onClick={onClickHandler}
        isExpanded={selectedIndex === index}
        className={`mb-4 ${selectedIndex === index && 'Accordion--isExpanded'}`}
        expandedContent={
          <>
            <p className="mb-4 font-semibold">Example project areas</p>
            <ReactMarkdown
              className="ResidencyGroup__project-examples"
              linkTarget="_blank"
            >
              {exampleProjectAreas}
            </ReactMarkdown>
          </>
        }
        collapsedContent={
          <p className="font-semibold">Example project areas</p>
        }
        hideAccordionAriaLabel="hide example project areas"
        showAccordionAriaLabel="expand example project areas"
        isFullyClickable={true}
        iconPosition="right"
        closedIconOrientation="down"
      />
      <div>
        <button
          onClick={() => scrollTo('#supervisors')}
          className="underline underline-offset-2 font-semibold"
        >
          Potential supervisors
        </button>
      </div>
    </div>
  )
}

export default ResidencyGroup
