import React from 'react'
import Participant from '../../Participant/Participant'

export interface IResidencySupervisorsProps {
  title: string
  subtitle: string
  showImages: boolean
  participants: IParticipant[]
}

const ResidencySupervisors = (props: IResidencySupervisorsProps) => {
  const { title, subtitle, showImages, participants } = props
  return (
    <div
      className="jumptarget"
      data-testid="participants-section"
      id="supervisors"
    >
      <div className="inner-container standard-page-section !pb-0">
        <p className="subtitle">{`// ${subtitle}`}</p>
        <h2>{title}</h2>
        <ul className="mt-8.5 grid gap-8 grid-cols-2 md:grid-cols-4 lg:grid-cols-5">
          {participants.map((participant, index) => (
            <li
              key={`${participant.name.replace(/\s+/g, '')}-${index}`}
              className="flex"
            >
              <Participant showImage={showImages} participant={participant} />
            </li>
          ))}
        </ul>
        <hr />
      </div>
    </div>
  )
}

export default ResidencySupervisors
