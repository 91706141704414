import Subscribe from '../../Subscribe/Subscribe'
import React from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { getImageDataByFilename } from '../../../utils/getImageDataByFilename'
import { GatsbyImage } from 'gatsby-plugin-image'

interface IResidencyHeroProps {
  content: {
    signUpURL: string
    registrationOpenDateTime: string
    registrationClosedDateTime: string
    hero: {
      title: string
      subtitle: string
      image: string
      imageAlt: string
      description: string
    }
  }
  isRegistrationOpen: boolean
}

const ResidencyHero = (props: IResidencyHeroProps) => {
  const { isRegistrationOpen } = props
  const { signUpURL } = props.content
  const { title, subtitle, image, imageAlt, description } = props.content.hero
  return (
    <div className="md:pb-30 pt-25 inner-container">
      <div className="flex flex-row">
        <div className="hidden md:block md:w-3.75/12">
          <GatsbyImage
            alt={imageAlt}
            image={getImageDataByFilename(image)}
            className="w-full"
          />
        </div>
        <div className="flex-1 md:pl-6 lg:pl-10">
          <p className="subtitle">{`// ${title}`}</p>
          <h1 className="mt-5">{subtitle}</h1>
          <p className="mt-5">{description}</p>
          {isRegistrationOpen ? (
            <div className="flex-col flex-wrap items-center mt-10 mb-5">
              <a
                href={signUpURL}
                target="_blank"
                className="mb-5 mr-2.5 btn btn-lg btn-1"
                rel="noopener noreferrer"
                data-testid="signup-link"
              >
                Apply now
              </a>
              <button
                onClick={() => scrollTo('#faq')}
                className="mx-2.5 btn btn-lg btn-2"
              >
                FAQ
              </button>
            </div>
          ) : (
            <div>
              <p className="mt-6 -mb-2 text-primary-600 font-bold text-sm">
                Applications are currently closed. Subscribe to our newsletter
                to get notified when they open.
              </p>
              <Subscribe emailInputId="residency-hero-email-input" />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ResidencyHero
