export default {
  metaTags: {
    title: `Xanadu Residency Program`,
    description: `A 17-week paid quantum computing summer program for graduate and undergraduate students in physics, computer science, and engineering.`,
    image: `residency-program-page-social-card.png`,
  },
  signUpURL:
    'https://xanadu.applytojob.com/apply/xzbA1xCtho/Xanadu-Residency-Program-2025',
  registrationOpenDateTime: '2024/09/03 9:00:00 EST', // Toronto timezone
  registrationClosedDateTime: '2024/11/30 17:59:59 EST', // Toronto timezone
  hero: {
    title: `Residency Program`,
    subtitle: `Fast-track your career in quantum`,
    description: `If you're a student looking to build your career in quantum — join us in May 2025 for our 17-week paid summer internship program in Toronto. Gain practical experience, create a professional network and work alongside our world-class team on projects spanning research, software and education. The program kicks off with a quantum bootcamp to set you up for success.`,
    image: `residency-hero.png`,
    imageAlt: `Residents touring the Xanadu lab`,
  },
  residencyGroupsSection: {
    title: 'Potential Projects',
    subtitle: 'Residency Groups',
    residencyGroups: [
      {
        title: 'Research',
        description:
          'Work on fundamental topics in quantum algorithms, quantum machine learning, and quantum error correction.',
        exampleProjectAreas: `
  - Characterizing the differences between quantum and classical machine learning models
  - Discovering more efficient quantum algorithms for quantum chemistry
  - Compiling Gaussian subcircuits
  - Designing more performant architectures for fault-tolerant quantum computing
  - Quantum compilation and circuit optimization with Lie theory
  - Quantum error correction research
  - Designing error correction techniques tailored to the hardware
        `,
        index: 0,
        onClickAccordion: () => null,
      },
      {
        title: 'Software',
        description:
          'Build cutting-edge quantum software tools that are used by thousands of researchers worldwide.',
        exampleProjectAreas: `
  - Developing new software functionality for [PennyLane](https://pennylane.ai)
  - Incorporating state of the art research results in PennyLane
  - Building compiler tools for large structured quantum circuits
        `,
        index: 1,
        onClickAccordion: () => null,
      },
      {
        title: 'Education',
        description:
          'Create high-quality educational content to help train the next generation of quantum scientists.',
        exampleProjectAreas: `
  - Writing and developing a PennyLane Codebook module
  - Creating coding challenges as part of various PennyLane events
  - Producing demos and tutorials on specialized quantum computing subjects
        `,
        index: 2,
        onClickAccordion: () => null,
      },
    ],
  },
  supervisorsSection: {
    subtitle: `World-class Leadership`,
    title: `Potential supervisors`,
    showImages: false,
    participants: [
      // `image`, `linkedInUrl`, `twitterUrl`, `githubUrl`, and `googleScholarUrl` are optional fields.
      // The order that participants are listed here is the order they will appear on the page.
      {
        name: `Maria Schuld`,
        affiliation: `Research`,
        image: `maria-schuld.jpeg`,
        twitterUrl: ``,
        githubUrl: `https://github.com/mariaschuld`,
        googleScholarUrl: `https://scholar.google.com/citations?user=_ih_hwUAAAAJ`,
        linkedInUrl: ``,
      },
      {
        name: `Priya Nadkarni`,
        affiliation: `Research`,
        image: ``,
        twitterUrl: ``,
        githubUrl: `https://github.com/PriNad`,
        googleScholarUrl: `https://scholar.google.com/citations?user=jyziqxIAAAAJ`,
        linkedInUrl: ``,
      },
      {
        name: `Juan Miguel Arrazola`,
        affiliation: `Research`,
        image: ``,
        twitterUrl: ``,
        githubUrl: `https://github.com/ixfoduap`,
        googleScholarUrl: `https://scholar.google.com/citations?user=10qgYZMAAAAJ`,
        linkedInUrl: ``,
      },
      {
        name: `Eli Bourassa`,
        affiliation: `Research`,
        image: ``,
        twitterUrl: ``,
        githubUrl: `https://github.com/elib20`,
        googleScholarUrl: `https://scholar.google.com/citations?user=BikFrPQAAAAJ&hl=en`,
        linkedInUrl: ``,
      },
      {
        name: `Ilan Tzitrin`,
        affiliation: `Research`,
        image: ``,
        twitterUrl: ``,
        githubUrl: `https://github.com/ilan-tz`,
        googleScholarUrl: `https://scholar.google.ca/citations?user=4sBJN1EAAAAJ&hl=en`,
        linkedInUrl: ``,
      },
      {
        name: `Filippo Miatto`,
        affiliation: `Research`,
        image: ``,
        twitterUrl: ``,
        githubUrl: `https://github.com/ziofil`,
        googleScholarUrl: `https://scholar.google.com/citations?user=zoaxT0QAAAAJ&hl=en`,
        linkedInUrl: ``,
      },
      {
        name: `Nathan Killoran`,
        affiliation: `Research`,
        image: ``,
        twitterUrl: ``,
        githubUrl: `https://github.com/co9olguy`,
        googleScholarUrl: `https://scholar.google.com/citations?user=4IXbCYMAAAAJ`,
        linkedInUrl: ``,
      },
      {
        name: `David Wierichs`,
        affiliation: `Research`,
        image: ``,
        twitterUrl: ``,
        githubUrl: `https://github.com/dwierichs`,
        googleScholarUrl: `https://scholar.google.com/citations?user=Y5sD6i0AAAAJ&hl=de`,
        linkedInUrl: ``,
      },
      {
        name: `Korbinian Kottmann`,
        affiliation: `Research`,
        image: ``,
        twitterUrl: ``,
        githubUrl: `https://github.com/Qottmann`,
        googleScholarUrl: `https://scholar.google.com/citations?user=KajN3IQAAAAJ&hl=en`,
        linkedInUrl: ``,
      },
      {
        name: `Josh Izaac`,
        affiliation: `Software`,
        image: ``,
        twitterUrl: ``,
        githubUrl: `https://github.com/josh146`,
        googleScholarUrl: `https://scholar.google.com.au/citations?user=pEj09c4AAAAJ&hl=en`,
        linkedInUrl: ``,
      },
      {
        name: `Lee O'Riordan`,
        affiliation: `Software`,
        image: ``,
        twitterUrl: ``,
        githubUrl: `https://github.com/mlxd`,
        googleScholarUrl: `https://scholar.google.com/citations?user=s-6jND0AAAAJ&hl=en`,
        linkedInUrl: ``,
      },
      {
        name: `Alvaro Ballon`,
        affiliation: `Education`,
        image: ``,
        twitterUrl: ``,
        githubUrl: `https://github.com/alvaro-at-xanadu`,
        googleScholarUrl: ``,
        linkedInUrl: ``,
      },
    ],
  },
  testimonialSlider: {
    title: `Testimonials`,
    subtitle: `Hear from our previous residents`,
    quotes: [
      {
        image: 'residency_testimonial_8.png',
        quote: `This program offers a unique opportunity to immerse yourself in one of the best environments for anyone working in quantum computing, with the added bonus of being in lively Toronto. The experiences and memories you'll create here will leave you with just one reaction when you look back: Oh wow!`,
        author: `Jorge Martinez de Lejarza, PhD Student | Education Intern`,
        authorSubtitle: ``,
      },
      {
        image: 'residency_testimonial_9.png',
        quote: `Sometimes there are experiences and events that change your perspective and alter the course of your life, the residency at Xanadu has definitely been such an experience. I would strongly recommend.`,
        author: `Praveen Jayakumar, PhD Student | Research Intern`,
        authorSubtitle: ``,
      },
      {
        image: 'residency_testimonial_1.png',
        quote: `The residency program at Xanadu has been a great experience that I cannot recommend enough to anyone interested in a semester-abroad-style internship in an exciting environment working on software, research and quantum computing.`,
        author: `Korbinian Kottmann, PhD student | Software Intern`,
        authorSubtitle: ``,
      },
      {
        image: 'residency_testimonial_2.png',
        quote: `This programme offered me the unique opportunity to work at a quantum start-up during my studies and it has been incredible. Xanadu has a culture of equality, and the people are welcoming, kind and supportive, allowing you to learn and thrive every day.`,
        author: `Joost Bus, MSc student | Research Intern`,
        authorSubtitle: ``,
      },
      {
        image: 'residency_testimonial_3.png',
        quote: `I would unhesitatingly recommend the residency program. It was a great way to meet other young quantum mechanics, get exposure to the quantum computing ecosystem, and learn from some of the best in the business. Plus it was a heap of fun!`,
        author: `David Wakeham, PhD student | Software Intern`,
        authorSubtitle: ``,
      },
      {
        image: 'residency_testimonial_4.png',
        quote: `Working with experts at the forefront of useful quantum computing was such a valuable experience. And the culture at Xanadu is so positive and inclusive, I really think it's unique. I got to take something I was passionate about and apply it.`,
        author: `A. Lowe, Masters student | Research Intern`,
        authorSubtitle: ``,
      },
      {
        image: 'residency_testimonial_5.png',
        quote: `Working at Xanadu has been an incredibly rewarding experience. I have had the opportunity to contribute daily to research on some of the most challenging problems in quantum technology in a fast-paced, team-oriented environment. A highlight of the program has been the generous culture of mentorship and ability to learn from top experts in the field.`,
        author: `E. Bourassa, PhD student | Research Intern`,
        authorSubtitle: ``,
      },
      {
        image: 'residency_testimonial_6.png',
        quote: `The residency was a fantastic experience of getting to learn from and work with so many intelligent and supportive people. I loved getting to meet and form friendships with researchers from all around the world. It was an interesting change of pace from foundational/abstract academic research to contribute towards the concrete and Herculean task of developing the world's first useful fault-tolerant quantum computer. I can't recommend it enough to students with an interest in the field.`,
        author: `Rio Weil, MSc student | Research Intern`,
        authorSubtitle: ``,
      },
      {
        image: 'residency_testimonial_7.png',
        quote: `The overall experience in the program was excellent. I could deep dive into the state of the art research of the photonic quantum computing with great researchers. Perhaps more importantly or not, I felt Toronto had a culture that accepted anyone in the world.`,
        author: `Ryosuke Noro, PhD student | Research Intern`,
        authorSubtitle: ``,
      },
    ],
  },
  imageGrid: [
    `image-grid-1.png`,
    `image-grid-2.png`,
    `image-grid-3.png`,
    `image-grid-4.png`, // This is a wide image.
    `image-grid-5.png`,
  ],
  featuredBlog: {
    title: `Blog`,
    subtitle: `Xanadu 2024 Residency Program`,
    description: `Meet some of the previous Xanadu residents and explore their research in this blog post.`,
    link: { text: `Read blog`, url: `/blog/Xanadu-2024-Residency-Program` },
    image: `residency_2024_blog_header.png`,
  },
  FAQs: [
    {
      question: `Who should apply to the Xanadu Residency Program?`,
      answer: `We consider candidates currently enrolled in undergraduate or graduate programs (Masters or PhDs), with experience in an academic program related to quantum computing, physics, computer science, engineering, or other applicable area of study.`,
    },
    {
      question: `When does the program take place?`,
      answer: `The program runs May-August. The Quantum Bootcamp will take place the first week of May.`,
    },
    {
      question: `Will the Xanadu Residency Program be in person?`,
      answer: `The Residency Program will be in person, with all participants working from our downtown Toronto office. A contingency plan is in place for the program to take place online, if needed.`,
    },
    {
      question: `Can people from outside Canada apply?`,
      answer: `Yes, applications from anywhere in the world will be considered.`,
    },
    {
      question: `Is there an application deadline to apply for the Residency Program?`,
      answer: `Applications for the 2025 program close on November 15, 2024 at 11.59pm Eastern Time (EST).`,
    },
    {
      question: `How will residents be supported during the Residency Program?`,
      answer: `In addition to working with a dedicated supervisor, each resident will be supported by the Xanadu Residency Program Team and awarded a stipend to cover their living expenses for the duration of the Residency Program. Travel costs to and from Toronto will also be covered.`,
    },
    {
      question: `How will applicants be considered?`,
      answer: `Applications will be reviewed on a rolling basis. With a limited amount of spots available, we encourage you to apply as soon as possible.`,
    },
    {
      question: `What subject areas are available to work on for research projects?`,
      answer: `Our main areas of focus are quantum machine learning, quantum computing, quantum chemistry, quantum software, and photonics. However, feel free to check out the profiles of our researchers and tell us what you're interested in.`,
    },
    {
      question: `Is it possible to do a research project focused on the hardware?`,
      answer: `Hardware is not one of the key internship areas. However, if you have significant experience and are specializing in hardware in your graduate program, please indicate this in your application.`,
    },
    {
      question: `What if I am interested in more than one internship area, e.g., research and software?`,
      answer: `That's great! Many of our projects span multiple categories. Please let us know your interests in your application.`,
    },
    {
      question: `What can I expect during the bootcamp portion of the program?`,
      answer: `The aim of the bootcamp is to bring everyone to the same base level of familiarity with quantum algorithms, programming best practices, and Xanadu's software tools. The bootcamp will be a mix of live lecture and tutorials, with emphasis on hands-on activities.`,
    },
    {
      question: `Can this count as an internship for my degree?`,
      answer: `Normally yes, but you should check with your academic institution.`,
    },
  ],
  readyToApply: {
    title: `Ready to apply?`,
    description: ``,
  },
}
