import React from 'react'
import './ImageGrid.css'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getImageDataByFilename } from '../../../utils/getImageDataByFilename'

interface IImageGridProps {
  images: string[]
}

const ImageGrid = (props: IImageGridProps) => {
  const { images } = props
  if (images.length === 5) {
    return (
      <div className="ImageGrid inner-container" data-testid="image-grid">
        {/* Display smaller images in two columns on mobile */}
        <ul className="columns-2 md:hidden">
          {images
            .filter((_image, index) => index !== 3)
            .map((image, index) => (
              <li key={index} className="mb-4">
                <GatsbyImage
                  image={getImageDataByFilename(image)}
                  data-testid={image}
                  alt=""
                />
              </li>
            ))}
        </ul>
        {/* Display wide image on its own at the bottom on mobile */}
        <div className="mb-4 block md:hidden grow">
          <GatsbyImage
            image={getImageDataByFilename(images[3])}
            data-testid={images[3]}
            alt=""
          />
        </div>
        {/* Tablet/desktop grid view */}
        <ul className="Masonry">
          {images.map((image, index) => (
            <li key={index} className={index === 3 ? 'Landscape' : ''}>
              <GatsbyImage
                image={getImageDataByFilename(image)}
                data-testid={image}
                alt=""
              />
            </li>
          ))}
        </ul>
      </div>
    )
  } else return null
}

export default ImageGrid
