import React from 'react'
import Accordion from '../../Accordion/Accordion'
import ReactMarkdown from 'react-markdown'
import './FAQ.css'

interface IFAQProps {
  FAQs: { question: string; answer: string }[]
}

const FAQ = (props: IFAQProps) => {
  const { FAQs } = props
  const [selectedFaq, setSelectedFaq] = React.useState<number | null>(null)

  if (FAQs.length > 0) {
    return (
      <div
        className="inner-container mb-12 scroll-mt-20"
        data-testid="faq-section"
        id="faq"
      >
        <h2 className="text-center mb-12">FAQ</h2>
        <ul className="max-w-[737px] mx-auto">
          {FAQs.map((faq, index) => (
            <li key={index} className="mb-8">
              <Accordion
                expandedContent={
                  <>
                    <p className="mb-4 font-semibold">{faq.question}</p>
                    <ReactMarkdown className="FAQ__answer" linkTarget="_blank">
                      {faq.answer}
                    </ReactMarkdown>
                  </>
                }
                collapsedContent={
                  <p className="font-semibold">{faq.question}</p>
                }
                hideAccordionAriaLabel="hide answer"
                showAccordionAriaLabel="expand answer"
                isFullyClickable={true}
                iconPosition="right"
                closedIconOrientation="down"
                isExpanded={selectedFaq === index}
                onClick={() => {
                  return index === selectedFaq
                    ? setSelectedFaq(null)
                    : setSelectedFaq(index)
                }}
              />
            </li>
          ))}
        </ul>
      </div>
    )
  } else return null
}

export default FAQ
