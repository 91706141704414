import * as React from 'react'
import ResidencyGroup, {
  IResidencyGroup,
} from './ResidencyGroup/ResidencyGroup'

export interface IResidencyGroupsProps {
  title: string
  subtitle: string
  residencyGroups: IResidencyGroup[]
}

const ResidencyGroups = ({
  title,
  subtitle,
  residencyGroups,
}: IResidencyGroupsProps) => {
  const [openResidencyGroup, setOpenResidencyGroup] = React.useState<
    number | null
  >(null)

  return (
    <section
      className="bg-grey-200"
      id="residency-groups"
      data-testid="residency-groups"
    >
      <div className="inner-container standard-page-section">
        <p className="subtitle">{`// ${title}`}</p>
        <h2 className="mb-8">{subtitle}</h2>
        <div className="flex flex-col md:flex-row gap-8">
          {residencyGroups.map((residencyGroup, index) => (
            <ResidencyGroup
              onClickAccordion={setOpenResidencyGroup}
              selectedIndex={openResidencyGroup}
              index={index}
              key={`residency-group-${index}`}
              title={residencyGroup.title}
              description={residencyGroup.description}
              exampleProjectAreas={residencyGroup.exampleProjectAreas}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default ResidencyGroups
