/* eslint-disable react/jsx-no-comment-textnodes */
import * as React from 'react'
import Layout from 'components/Layout'
import ResidencyDetails from '../components/ResidencyDetails/ResidencyDetails'
import ResidencyPageContent from '../../content/residency'
import Seo from '../components/Seo'

const ResidencyPage = () => {
  return (
    <Layout>
      <ResidencyDetails content={ResidencyPageContent} />
    </Layout>
  )
}

export default ResidencyPage

export const Head = () => {
  const { title, description, image } = ResidencyPageContent.metaTags
  return (
    <Seo
      title={title}
      description={description}
      image={image}
      imageBasePath="/residency/"
    />
  )
}
