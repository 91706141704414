import React, { useMemo } from 'react'
import { isBefore, isAfter } from 'date-fns'
import ResidencyHero from './ResidencyHero/ResidencyHero'
import ResidencyGroups, {
  IResidencyGroupsProps,
} from './ResidencyGroups/ResidencyGroups'
import QuoteSlider from '../QuoteSlider/QuoteSlider'
import ResidencySupervisors, {
  IResidencySupervisorsProps,
} from './ResidencySupervisors/ResidencySupervisors'
import ImageGrid from './ImageGrid/ImageGrid'
import FeaturedLink from '../FeaturedLink/FeaturedLink'
import FAQ from '../ResidencyDetails/FAQ/FAQ'
import ReadyToApply from './ReadyToApply/ReadyToApply'

interface IResidencyDetailsProps {
  content: {
    signUpURL: string
    registrationOpenDateTime: string
    registrationClosedDateTime: string
    hero: {
      title: string
      subtitle: string
      image: string
      imageAlt: string
      description: string
    }
    residencyGroupsSection: IResidencyGroupsProps
    supervisorsSection: IResidencySupervisorsProps
    testimonialSlider: {
      title: string
      subtitle: string
      quotes: {
        image: string
        quote: string
        author: string
        authorSubtitle: string
      }[]
    }
    imageGrid: string[]
    featuredBlog: {
      title: string
      subtitle: string
      description: string
      link: { text: string; url: string }
      image: string
    }
    FAQs: { question: string; answer: string }[]
    readyToApply: { title: string; description: string }
  }
}

const ResidencyDetails = (props: IResidencyDetailsProps) => {
  const {
    signUpURL,
    registrationOpenDateTime,
    registrationClosedDateTime,
    residencyGroupsSection,
    supervisorsSection,
    testimonialSlider,
    imageGrid,
    featuredBlog,
    FAQs,
    readyToApply,
  } = props.content

  const {
    title: residencyGroupsTitle,
    subtitle: residencyGroupsSubtitle,
    residencyGroups,
  } = residencyGroupsSection

  const {
    title: supervisorsSectionTitle,
    subtitle: supervisorsSectionSubtitle,
    participants,
    showImages,
  } = supervisorsSection

  const isRegistrationOpen = useMemo(() => {
    return (
      isAfter(Date.now(), new Date(registrationOpenDateTime)) &&
      isBefore(Date.now(), new Date(registrationClosedDateTime)) &&
      signUpURL !== ''
    )
  }, [])

  return (
    <>
      <ResidencyHero
        content={props.content}
        isRegistrationOpen={isRegistrationOpen}
      />
      <ResidencyGroups
        title={residencyGroupsTitle}
        subtitle={residencyGroupsSubtitle}
        residencyGroups={residencyGroups}
      />
      <div className="mt-14">
        <QuoteSlider content={testimonialSlider} />
      </div>
      <ResidencySupervisors
        subtitle={supervisorsSectionSubtitle}
        title={supervisorsSectionTitle}
        participants={participants}
        showImages={showImages}
      />
      <ImageGrid images={imageGrid} />
      <div className="bg-grey-200 w-full">
        <FeaturedLink
          title={featuredBlog.title}
          subtitle={featuredBlog.subtitle}
          description={featuredBlog.description}
          link={featuredBlog.link}
          image={featuredBlog.image}
          imageLeft={true}
        />
      </div>
      <FAQ FAQs={FAQs} />
      <ReadyToApply
        signUpURL={signUpURL}
        readyToApply={readyToApply}
        isRegistrationOpen={isRegistrationOpen}
      />
    </>
  )
}

export default ResidencyDetails
