import Subscribe from '../../Subscribe/Subscribe'
import React from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll'
import './ReadyToApply.css'

export interface IReadyToApplyProps {
  signUpURL: string
  readyToApply: {
    title: string
    description: string
  }
  isRegistrationOpen: boolean
}

const ReadyToApply = (props: IReadyToApplyProps) => {
  const { isRegistrationOpen, readyToApply, signUpURL } = props
  const { title, description } = readyToApply

  return (
    <div className="ReadyToApply inner-container" data-testid="ready-to-apply">
      <div className="flex">
        <div className="flex-1">
          <h1 className="ReadyToApply__title">{title}</h1>
          <p className="ReadyToApply__description">{description}</p>
          {isRegistrationOpen ? (
            <div className="ReadyToApply__registration-open">
              <a
                href={signUpURL}
                target="_blank"
                className="ReadyToApply__button btn btn-1"
                rel="noopener noreferrer"
                data-testid="signup-link"
              >
                Apply now
              </a>
              <button
                onClick={() => scrollTo('#faq')}
                className="ReadyToApply__button btn btn-2"
              >
                FAQ
              </button>
            </div>
          ) : (
            <div>
              <p className="ReadyToApply__registration-closed">
                Applications are currently closed. Subscribe to our newsletter
                to get notified when they open.
              </p>
              <div className="flex justify-center">
                <Subscribe emailInputId="ready-to-apply-email-input" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ReadyToApply
