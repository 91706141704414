import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import './Accordion.css'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface IAccordionProps {
  /** Content to display when the accordion is expanded */
  expandedContent: JSX.Element
  /** Optionally display alternative content when the accordion is collapsed */
  collapsedContent?: JSX.Element
  /** className for styling */
  className?: string
  /** Is expanded */
  isExpanded: boolean
  /** Icon position */
  iconPosition?: 'left' | 'right'
  /** Make entire accordion clickable */
  isFullyClickable?: boolean
  /** Aria label text for collapsed button*/
  hideAccordionAriaLabel?: string
  /** Aria label text for expand button*/
  showAccordionAriaLabel?: string
  /** Orientation of icon */
  closedIconOrientation?: 'left' | 'right' | 'up' | 'down'
  /** Optional extra click handler */
  onClick?: () => void
}

const Accordion = (props: IAccordionProps) => {
  const {
    expandedContent,
    collapsedContent,
    className,
    isExpanded,
    iconPosition = 'left',
    closedIconOrientation = 'right',
    isFullyClickable = false,
    hideAccordionAriaLabel = 'hide accordion content',
    showAccordionAriaLabel = 'show accordion content',
    onClick,
  } = props

  const getAccordionContent = () => {
    if (isExpanded) {
      return <div>{expandedContent}</div>
    } else if (!isExpanded && collapsedContent) {
      return <div>{collapsedContent}</div>
    } else return null
  }

  const getIconRotation = () => {
    switch (closedIconOrientation) {
      case 'left':
        return 'rotate-90'
      case 'right':
        return '-rotate-90'
      case 'up':
        return 'rotate-180'
      default:
        return 'rotate-90'
    }
  }

  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <div
      className={`Accordion ${className ? className : ''} ${
        isFullyClickable ? 'cursor-pointer' : ''
      }`}
      onClick={() => (isFullyClickable ? handleClick() : null)}
    >
      <>
        {iconPosition === 'right' && (
          <div
            className={`Accordion__content ${
              isExpanded
                ? 'Accordion__content--expanded'
                : 'Accordion__content--collapsed'
            }`}
          >
            {getAccordionContent()}
          </div>
        )}
        <button
          onClick={() => (!isFullyClickable ? handleClick() : null)}
          aria-label={
            isExpanded ? hideAccordionAriaLabel : showAccordionAriaLabel
          }
          className="Accordion__button"
          data-testid="accordion-button"
        >
          <FontAwesomeIcon
            className={`Accordion__icon ${
              !isExpanded && closedIconOrientation !== 'down'
                ? getIconRotation()
                : ''
            } ${
              !isExpanded && closedIconOrientation === 'down'
                ? ''
                : '-rotate-180'
            }`}
            icon={faChevronDown as IconProp}
          />
        </button>
      </>
      {iconPosition === 'left' && (
        <div
          className={`Accordion__content ${
            isExpanded
              ? 'Accordion__content--expanded'
              : 'Accordion__content--collapsed'
          }`}
        >
          {getAccordionContent()}
        </div>
      )}
    </div>
  )
}

export default Accordion
